.App {
  text-align: center;
  background-color: rgba(56, 63, 51, 1);
  padding-top: 30px;
  padding-bottom: 100px;
  overflow: hidden;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: rgba(56, 63, 51, 1);
  min-height: 60vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(228, 231, 228);
  font-family: 'Lato', sans-serif;
  padding-top: 24px;
  padding-bottom: 24px;
}

.App-link {
  color:  brown;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.about-me {
  padding-bottom: 8px;
  margin: 0;
}

a {
  text-decoration: none;
  color: brown;
}

.bottom-footer {
  display: inline-block;
  width: 100%;
  padding-bottom: 12px;
  background-color: rgba(56, 63, 51, 1);
}

.copyright-footer-item {
    margin-right: 20px;
    margin-left: 0px;
    opacity: 0.8;
    color: rgb(228, 231, 228);
    font-size: 12px;
    line-height: 20px;
    font-weight: 700;
    letter-spacing: 1px;
    text-decoration: none;
    text-transform: uppercase;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
}

img, embed, object, video {
  max-width: 100%;
}

@media (max-width: 960px) {
  .App-header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    flex-direction: column;
  }

  .font-brand {
    margin-right: 14px;
  }

  .github, .insta, .twitter, .linkedin{
    margin-right: 14px;
  }

  .github {
    margin-left: 14px;
  }

  .bottom-footer {
    display: flex;
    flex-direction: column;
    max-width: 350px;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
  }

  .name-header {
    font-size: 60px !important;
  }

  .about-me-container { 
    margin-left: 24px;
    width: 100%;
  }

  .vertical-name-header { 
    transform: unset !important;
  }

  .content { 
    width: unset !important;
  }

  .name-header-container {
    display: flex;
    flex-direction: column;
    align-self: center;
  }
}

@media (max-width: 600px) {
  .App-header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    flex-direction: column;
  }

  .font-brand {
    margin-right: 14px;
  }

  .github, .insta, .twitter, .linkedin{
    margin-right: 14px;
  }

  .github {
    margin-left: 14px;
  }

  .bottom-footer {
    display: flex;
    flex-direction: column;
    max-width: 350px;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
  }

  .name-header {
    font-size: 60px !important;
  }

  .about-me-container { 
    margin-left: 24px;
    width: 100%;
  }

  .vertical-name-header { 
    transform: unset !important;
  }

  .content { 
    width: unset !important;
  }
}

.makeStyles-root-1 {
  overflow: hidden;
}

html, body, footer { margin: 0; padding: 0 }

html {
  overflow: auto;
    height: 100%;
}

body {
  overflow: auto;
  height: 100%;
  background-color: rgba(56, 63, 51, 1);
}