.name-header {
    font-family: 'Karla', sans-serif;
    font-size: 88px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -7.04px;
    line-height: 120%;
    text-decoration: none;
    text-transform: none;
    margin-top: 0;
    margin-bottom: 0;
}

.about-me {
    font-family: "Inconsolata", monospace;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 150%;
    text-decoration: none;
    text-transform: none;
}


.about-me a {
    color:brown;
}

.about-me-container {
    display: flex;
    flex-direction: row;
    text-align: left;
}

.about-me {
    margin-right: 52px;
}

.vertical-name-header {
    outline: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    opacity: 1;
    flex-shrink: 0;
    transform: perspective(1200px) translateY(-50%) rotate(-90deg);
    font-family: "Karla", sans-serif;
    font-size: 72px;
    font-weight: 500;
    letter-spacing: -7.04px;
    line-height: 120%;
}

img.icon-image {
    width: 85%;
    max-width: 400px;
    margin-right: 64px;
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 8px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    transform: perspective(1200px);
}

.content {
    align-items: flex-start;
    display: flex;
    flex: 1 0 0px;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 24px;
    height: min-content;
    justify-content: flex-start;
    overflow: visible;
    padding: 0;
    position: relative;
    width: 1px;
}

.MuiTimeline-root.MuiTimeline-alignAlternate {
    text-align: left;
}

@media (max-width: 1350px) {
    .about-me-container {
        flex-direction: column;
        text-align: justify;
    }
}

@media (max-width: 1050px) {
    .about-me-container {
        flex-direction: column;
        text-align: justify;
    }

    img.icon-image {
        width: 80%;
        padding: 12px;
        align-self: center;
        margin: unset;
    }

}

/* @media (max-width: 800px) {

} */

@media (max-width: 520px) {
    .App-header {
        padding: auto;
    }

    img.icon-image {
        height: auto; 
        width: auto;
        max-width: 300px; 
        max-height: 300px;
    }

    .about-me-container {
        flex-direction: column;
    }

}